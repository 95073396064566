//Global variable for spellchecker
var l;

function signatureCapture() {
	var canvas = document.getElementById("signatureFromMouse");
	if(canvas)
	{
		var context = canvas.getContext("2d");

		if (!context) {
			throw new Error("Failed to get canvas' 2d context");
		}
		canvas.width = canvas.clientWidth ;
		canvas.height = canvas.clientHeight ;
		
		context.fillStyle = "#fff";
		context.strokeStyle = "#444";
		context.lineWidth = 1.2;
		context.lineCap = "round";
	
		context.fillRect(0, 0, canvas.width, canvas.height);
	
		context.fillStyle = "#3a87ad";
		context.strokeStyle = "#3a87ad";
		context.lineWidth = 1;
		context.moveTo(20,220);
		context.lineTo(454,220);
		context.stroke();
	
		context.fillStyle = "#fff";
		context.strokeStyle = "#444";
		
		
		var disableSave = true;
		var pixels = [];
		var cpixels = [];
		var xyLast = {};
		var xyAddLast = {};
		var calculate = false;
		//functions
		{
			function remove_event_listeners() {
				canvas.removeEventListener('mousemove', on_mousemove, false);
				canvas.removeEventListener('mouseup', on_mouseup, false);
				canvas.removeEventListener('touchmove', on_mousemove, false);
				canvas.removeEventListener('touchend', on_mouseup, false);
	
				document.body.removeEventListener('mouseup', on_mouseup, false);
				document.body.removeEventListener('touchend', on_mouseup, false);
			}
	
			function get_board_coords(e) {
				var x, y;
	
				if (e.changedTouches && e.changedTouches[0]) {
					var rect = canvas.getBoundingClientRect();
					var touch=e.changedTouches[0];
					x = touch.pageX - rect.left;
					y = touch.pageY - rect.top;		
				} else if (e.layerX || 0 == e.layerX) {
					x = e.layerX;
					y = e.layerY;
				} else if (e.offsetX || 0 == e.offsetX) {
					x = e.offsetX;
					y = e.offsetY;
				}
				return {
					x : x,
					y : y
				};
			};
	
			function on_mousedown(e) {
				e.preventDefault();
				e.stopPropagation();
                window.EditItemComponent.changesSignature=true;
				canvas.addEventListener('mousemove', on_mousemove, false);
				canvas.addEventListener('mouseup', on_mouseup, false);
				canvas.addEventListener('touchmove', on_mousemove, false);
				canvas.addEventListener('touchend', on_mouseup, false);
	
				document.body.addEventListener('mouseup', on_mouseup, false);
				document.body.addEventListener('touchend', on_mouseup, false);
	
				empty = false;
				var xy = get_board_coords(e);
				context.beginPath();
				pixels.push('moveStart');
				context.moveTo(xy.x, xy.y);
				pixels.push(xy.x, xy.y);
				xyLast = xy;
			};
	
			function on_mousemove(e, finish) {
				e.preventDefault();
				e.stopPropagation();
                window.EditItemComponent.changesSignature=true;
				var xy = get_board_coords(e);
				var xyAdd = {
					x : (xyLast.x + xy.x) / 2,
					y : (xyLast.y + xy.y) / 2
				};
	
				if (calculate) {
					var xLast = (xyAddLast.x + xyLast.x + xyAdd.x) / 3;
					var yLast = (xyAddLast.y + xyLast.y + xyAdd.y) / 3;
					pixels.push(xLast, yLast);
				} else {
					calculate = true;
				}
	
				context.quadraticCurveTo(xyLast.x, xyLast.y, xyAdd.x, xyAdd.y);
				pixels.push(xyAdd.x, xyAdd.y);
				context.stroke();
				context.beginPath();
				context.moveTo(xyAdd.x, xyAdd.y);
				xyAddLast = xyAdd;
				xyLast = xy;
	
			};
	
			function on_mouseup(e) {
                window.EditItemComponent.changesSignature=true;
				remove_event_listeners();
				disableSave = false;
				context.stroke();
				pixels.push('e');
				calculate = false;
			};
	
		}//end
	
		canvas.addEventListener('mousedown', on_mousedown, false);
		canvas.addEventListener('touchstart', on_mousedown, false);
	}
}

function formatBlockCode(){
    document.querySelectorAll('pre').forEach((block) => { 
        hljs.highlightBlock(block); 
    }); 
}

function getHammerInstance(id,globalCount)
{
    var element = document.getElementById('inline_preview_'+id);
    if(element)
    {
        element.id='inline_preview_'+id+"_"+globalCount;
        return new Hammer(element);
    }
    return undefined;
}
